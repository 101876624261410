import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9b5759c8 = () => interopDefault(import('../pages/add-home.vue' /* webpackChunkName: "pages/add-home" */))
const _146deca5 = () => interopDefault(import('../pages/agree-terms.vue' /* webpackChunkName: "pages/agree-terms" */))
const _048f3a82 = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _02db7c73 = () => interopDefault(import('../pages/doctor/index.vue' /* webpackChunkName: "pages/doctor/index" */))
const _381428c1 = () => interopDefault(import('../pages/group/index.vue' /* webpackChunkName: "pages/group/index" */))
const _3744bfbb = () => interopDefault(import('../pages/hospitals/index.vue' /* webpackChunkName: "pages/hospitals/index" */))
const _31922426 = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _44301ca8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6aedf28f = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _3c48a61d = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _0e9d467f = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _732eb9b4 = () => interopDefault(import('../pages/passwordSetting.vue' /* webpackChunkName: "pages/passwordSetting" */))
const _8d3f74a4 = () => interopDefault(import('../pages/posting_information.vue' /* webpackChunkName: "pages/posting_information" */))
const _186f2db0 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _55a1b63a = () => interopDefault(import('../pages/regist-confirm.vue' /* webpackChunkName: "pages/regist-confirm" */))
const _43112f79 = () => interopDefault(import('../pages/retry-register.vue' /* webpackChunkName: "pages/retry-register" */))
const _1fed6c0c = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _fb74dc0e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _e3a5352e = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _248637c6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _405426a0 = () => interopDefault(import('../pages/unreceived_auth_code.vue' /* webpackChunkName: "pages/unreceived_auth_code" */))
const _2e10133e = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _a4fde85c = () => interopDefault(import('../pages/business-card/holder/index.vue' /* webpackChunkName: "pages/business-card/holder/index" */))
const _9776edac = () => interopDefault(import('../pages/business-card/requests/index.vue' /* webpackChunkName: "pages/business-card/requests/index" */))
const _73f1db59 = () => interopDefault(import('../pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _83b14760 = () => interopDefault(import('../pages/contact/confirm.vue' /* webpackChunkName: "pages/contact/confirm" */))
const _0d6d3d64 = () => interopDefault(import('../pages/contact/form.vue' /* webpackChunkName: "pages/contact/form" */))
const _55cb3644 = () => interopDefault(import('../pages/doctor/new/index.vue' /* webpackChunkName: "pages/doctor/new/index" */))
const _0452eb57 = () => interopDefault(import('../pages/doctor/search.vue' /* webpackChunkName: "pages/doctor/search" */))
const _cfc58762 = () => interopDefault(import('../pages/group/new.vue' /* webpackChunkName: "pages/group/new" */))
const _a31e4418 = () => interopDefault(import('../pages/hospitals/edit/index.vue' /* webpackChunkName: "pages/hospitals/edit/index" */))
const _5d12110f = () => interopDefault(import('../pages/hospitals/search.vue' /* webpackChunkName: "pages/hospitals/search" */))
const _f00efe24 = () => interopDefault(import('../pages/invitation/complete.vue' /* webpackChunkName: "pages/invitation/complete" */))
const _f5db8dfe = () => interopDefault(import('../pages/lists/hospitals/index.vue' /* webpackChunkName: "pages/lists/hospitals/index" */))
const _17843365 = () => interopDefault(import('../pages/lists/new.vue' /* webpackChunkName: "pages/lists/new" */))
const _85cf9d7e = () => interopDefault(import('../pages/medical/confirm.vue' /* webpackChunkName: "pages/medical/confirm" */))
const _99af90ac = () => interopDefault(import('../pages/medical/profile.vue' /* webpackChunkName: "pages/medical/profile" */))
const _1038bea7 = () => interopDefault(import('../pages/medical/signup.vue' /* webpackChunkName: "pages/medical/signup" */))
const _212d7c2b = () => interopDefault(import('../pages/migration/complete.vue' /* webpackChunkName: "pages/migration/complete" */))
const _eb146318 = () => interopDefault(import('../pages/migration/registered.vue' /* webpackChunkName: "pages/migration/registered" */))
const _f397bb1a = () => interopDefault(import('../pages/ms/search/index.vue' /* webpackChunkName: "pages/ms/search/index" */))
const _c2e18e8a = () => interopDefault(import('../pages/mypage/search.vue' /* webpackChunkName: "pages/mypage/search" */))
const _5bd77aee = () => interopDefault(import('../pages/notices/new/index.vue' /* webpackChunkName: "pages/notices/new/index" */))
const _49a95070 = () => interopDefault(import('../pages/rooms/new.vue' /* webpackChunkName: "pages/rooms/new" */))
const _58b2e0dc = () => interopDefault(import('../pages/setting/mail_magazine.vue' /* webpackChunkName: "pages/setting/mail_magazine" */))
const _32820ed9 = () => interopDefault(import('../pages/top/medical/index.vue' /* webpackChunkName: "pages/top/medical/index" */))
const _de521e1e = () => interopDefault(import('../pages/top/ms.vue' /* webpackChunkName: "pages/top/ms" */))
const _133ebeb2 = () => interopDefault(import('../pages/doctor/new/confirm.vue' /* webpackChunkName: "pages/doctor/new/confirm" */))
const _2a7bcb5e = () => interopDefault(import('../pages/doctor/new/profile/index.vue' /* webpackChunkName: "pages/doctor/new/profile/index" */))
const _52011e0c = () => interopDefault(import('../pages/doctor/new/select.vue' /* webpackChunkName: "pages/doctor/new/select" */))
const _3e35d84d = () => interopDefault(import('../pages/group/member/add.vue' /* webpackChunkName: "pages/group/member/add" */))
const _30a51ca3 = () => interopDefault(import('../pages/hospitals/edit/bed.vue' /* webpackChunkName: "pages/hospitals/edit/bed" */))
const _d6b32a58 = () => interopDefault(import('../pages/hospitals/edit/medical_staff.vue' /* webpackChunkName: "pages/hospitals/edit/medical_staff" */))
const _598744dd = () => interopDefault(import('../pages/hospitals/edit/open_bed/index.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/index" */))
const _83c79ef0 = () => interopDefault(import('../pages/hospitals/edit/outpatient/index.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/index" */))
const _7c290c76 = () => interopDefault(import('../pages/hospitals/edit/patient_date.vue' /* webpackChunkName: "pages/hospitals/edit/patient_date" */))
const _58a64c2a = () => interopDefault(import('../pages/hospitals/edit/result.vue' /* webpackChunkName: "pages/hospitals/edit/result" */))
const _e11e79e0 = () => interopDefault(import('../pages/lists/hospitals/add.vue' /* webpackChunkName: "pages/lists/hospitals/add" */))
const _db087ce0 = () => interopDefault(import('../pages/notices/new/select.vue' /* webpackChunkName: "pages/notices/new/select" */))
const _01fbc7eb = () => interopDefault(import('../pages/doctor/new/profile/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/new/profile/doctorExpertCategory" */))
const _a81a56ba = () => interopDefault(import('../pages/doctor/new/profile/pending.vue' /* webpackChunkName: "pages/doctor/new/profile/pending" */))
const _17e7621b = () => interopDefault(import('../pages/doctor/new/profile/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/searchPaperMeshList" */))
const _5fb7a7cc = () => interopDefault(import('../pages/doctor/new/profile/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/surgeryMeshList" */))
const _98c121ac = () => interopDefault(import('../pages/doctor/new/profile/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/unacceptableMeshList" */))
const _4613c1c4 = () => interopDefault(import('../pages/hospitals/edit/outpatient/edit.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/edit" */))
const _070b259a = () => interopDefault(import('../pages/doctor/new/profile/editHospital/_id.vue' /* webpackChunkName: "pages/doctor/new/profile/editHospital/_id" */))
const _8e2a4af6 = () => interopDefault(import('../pages/hospitals/edit/open_bed/_id.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/_id" */))
const _86d3b2b2 = () => interopDefault(import('../pages/group/notice/_id/index.vue' /* webpackChunkName: "pages/group/notice/_id/index" */))
const _2e2c87a9 = () => interopDefault(import('../pages/lists/hospitals/_id.vue' /* webpackChunkName: "pages/lists/hospitals/_id" */))
const _6deb25de = () => interopDefault(import('../pages/doctor/_id/index.vue' /* webpackChunkName: "pages/doctor/_id/index" */))
const _798e09a8 = () => interopDefault(import('../pages/group/_id/index.vue' /* webpackChunkName: "pages/group/_id/index" */))
const _2ae574e3 = () => interopDefault(import('../pages/hospitals/_id.vue' /* webpackChunkName: "pages/hospitals/_id" */))
const _54dcf0d6 = () => interopDefault(import('../pages/invitation/_id.vue' /* webpackChunkName: "pages/invitation/_id" */))
const _d81c62b6 = () => interopDefault(import('../pages/ms/_id/index.vue' /* webpackChunkName: "pages/ms/_id/index" */))
const _73f76a88 = () => interopDefault(import('../pages/notices/_id/index.vue' /* webpackChunkName: "pages/notices/_id/index" */))
const _5978dca7 = () => interopDefault(import('../pages/notification/_id.vue' /* webpackChunkName: "pages/notification/_id" */))
const _b222723a = () => interopDefault(import('../pages/papers/_id.vue' /* webpackChunkName: "pages/papers/_id" */))
const _4cd8d7e5 = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))
const _0cd8549e = () => interopDefault(import('../pages/doctor/_id/edit/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/index" */))
const _5abddac0 = () => interopDefault(import('../pages/group/_id/edit.vue' /* webpackChunkName: "pages/group/_id/edit" */))
const _467a4524 = () => interopDefault(import('../pages/group/_id/history.vue' /* webpackChunkName: "pages/group/_id/history" */))
const _1d2fa233 = () => interopDefault(import('../pages/group/_id/member/index.vue' /* webpackChunkName: "pages/group/_id/member/index" */))
const _adeadd6c = () => interopDefault(import('../pages/ms/_id/edit/index.vue' /* webpackChunkName: "pages/ms/_id/edit/index" */))
const _06a9eb47 = () => interopDefault(import('../pages/notices/_id/edit/index.vue' /* webpackChunkName: "pages/notices/_id/edit/index" */))
const _29a63ac7 = () => interopDefault(import('../pages/rooms/_id/edit.vue' /* webpackChunkName: "pages/rooms/_id/edit" */))
const _046def6f = () => interopDefault(import('../pages/rooms/_id/members/index.vue' /* webpackChunkName: "pages/rooms/_id/members/index" */))
const _b9611822 = () => interopDefault(import('../pages/doctor/_id/edit/category.vue' /* webpackChunkName: "pages/doctor/_id/edit/category" */))
const _164d2590 = () => interopDefault(import('../pages/doctor/_id/edit/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/_id/edit/doctorExpertCategory" */))
const _afcef90a = () => interopDefault(import('../pages/doctor/_id/edit/email.vue' /* webpackChunkName: "pages/doctor/_id/edit/email" */))
const _4d1c519b = () => interopDefault(import('../pages/doctor/_id/edit/papers/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/index" */))
const _51495f2e = () => interopDefault(import('../pages/doctor/_id/edit/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/searchPaperMeshList" */))
const _8eec7f46 = () => interopDefault(import('../pages/doctor/_id/edit/specialty.vue' /* webpackChunkName: "pages/doctor/_id/edit/specialty" */))
const _65f1265f = () => interopDefault(import('../pages/doctor/_id/edit/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/surgeryMeshList" */))
const _b305d712 = () => interopDefault(import('../pages/doctor/_id/edit/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/unacceptableMeshList" */))
const _514f4f02 = () => interopDefault(import('../pages/group/_id/notice/new.vue' /* webpackChunkName: "pages/group/_id/notice/new" */))
const _41f75c57 = () => interopDefault(import('../pages/notices/_id/edit/select.vue' /* webpackChunkName: "pages/notices/_id/edit/select" */))
const _49833cfe = () => interopDefault(import('../pages/rooms/_id/members/add.vue' /* webpackChunkName: "pages/rooms/_id/members/add" */))
const _d499bc9c = () => interopDefault(import('../pages/doctor/_id/edit/papers/delete.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/delete" */))
const _0841f99a = () => interopDefault(import('../pages/doctor/_id/edit/hospitals/_index.vue' /* webpackChunkName: "pages/doctor/_id/edit/hospitals/_index" */))
const _4f6de633 = () => interopDefault(import('../pages/rooms/_id/medias/_mediaId.vue' /* webpackChunkName: "pages/rooms/_id/medias/_mediaId" */))
const _7a887991 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-home",
    component: _9b5759c8,
    name: "add-home"
  }, {
    path: "/agree-terms",
    component: _146deca5,
    name: "agree-terms"
  }, {
    path: "/confirm",
    component: _048f3a82,
    name: "confirm"
  }, {
    path: "/doctor",
    component: _02db7c73,
    name: "doctor"
  }, {
    path: "/group",
    component: _381428c1,
    name: "group"
  }, {
    path: "/hospitals",
    component: _3744bfbb,
    name: "hospitals"
  }, {
    path: "/invitation",
    component: _31922426,
    name: "invitation"
  }, {
    path: "/login",
    component: _44301ca8,
    name: "login"
  }, {
    path: "/mypage",
    component: _6aedf28f,
    name: "mypage"
  }, {
    path: "/notices",
    component: _3c48a61d,
    name: "notices"
  }, {
    path: "/notification",
    component: _0e9d467f,
    name: "notification"
  }, {
    path: "/passwordSetting",
    component: _732eb9b4,
    name: "passwordSetting"
  }, {
    path: "/posting_information",
    component: _8d3f74a4,
    name: "posting_information"
  }, {
    path: "/profile",
    component: _186f2db0,
    name: "profile"
  }, {
    path: "/regist-confirm",
    component: _55a1b63a,
    name: "regist-confirm"
  }, {
    path: "/retry-register",
    component: _43112f79,
    name: "retry-register"
  }, {
    path: "/rooms",
    component: _1fed6c0c,
    name: "rooms"
  }, {
    path: "/search",
    component: _fb74dc0e,
    name: "search"
  }, {
    path: "/signup",
    component: _e3a5352e,
    name: "signup"
  }, {
    path: "/terms",
    component: _248637c6,
    name: "terms"
  }, {
    path: "/unreceived_auth_code",
    component: _405426a0,
    name: "unreceived_auth_code"
  }, {
    path: "/welcome",
    component: _2e10133e,
    name: "welcome"
  }, {
    path: "/business-card/holder",
    component: _a4fde85c,
    name: "business-card-holder"
  }, {
    path: "/business-card/requests",
    component: _9776edac,
    name: "business-card-requests"
  }, {
    path: "/contact/complete",
    component: _73f1db59,
    name: "contact-complete"
  }, {
    path: "/contact/confirm",
    component: _83b14760,
    name: "contact-confirm"
  }, {
    path: "/contact/form",
    component: _0d6d3d64,
    name: "contact-form"
  }, {
    path: "/doctor/new",
    component: _55cb3644,
    name: "doctor-new"
  }, {
    path: "/doctor/search",
    component: _0452eb57,
    name: "doctor-search"
  }, {
    path: "/group/new",
    component: _cfc58762,
    name: "group-new"
  }, {
    path: "/hospitals/edit",
    component: _a31e4418,
    name: "hospitals-edit"
  }, {
    path: "/hospitals/search",
    component: _5d12110f,
    name: "hospitals-search"
  }, {
    path: "/invitation/complete",
    component: _f00efe24,
    name: "invitation-complete"
  }, {
    path: "/lists/hospitals",
    component: _f5db8dfe,
    name: "lists-hospitals"
  }, {
    path: "/lists/new",
    component: _17843365,
    name: "lists-new"
  }, {
    path: "/medical/confirm",
    component: _85cf9d7e,
    name: "medical-confirm"
  }, {
    path: "/medical/profile",
    component: _99af90ac,
    name: "medical-profile"
  }, {
    path: "/medical/signup",
    component: _1038bea7,
    name: "medical-signup"
  }, {
    path: "/migration/complete",
    component: _212d7c2b,
    name: "migration-complete"
  }, {
    path: "/migration/registered",
    component: _eb146318,
    name: "migration-registered"
  }, {
    path: "/ms/search",
    component: _f397bb1a,
    name: "ms-search"
  }, {
    path: "/mypage/search",
    component: _c2e18e8a,
    name: "mypage-search"
  }, {
    path: "/notices/new",
    component: _5bd77aee,
    name: "notices-new"
  }, {
    path: "/rooms/new",
    component: _49a95070,
    name: "rooms-new"
  }, {
    path: "/setting/mail_magazine",
    component: _58b2e0dc,
    name: "setting-mail_magazine"
  }, {
    path: "/top/medical",
    component: _32820ed9,
    name: "top-medical"
  }, {
    path: "/top/ms",
    component: _de521e1e,
    name: "top-ms"
  }, {
    path: "/doctor/new/confirm",
    component: _133ebeb2,
    name: "doctor-new-confirm"
  }, {
    path: "/doctor/new/profile",
    component: _2a7bcb5e,
    name: "doctor-new-profile"
  }, {
    path: "/doctor/new/select",
    component: _52011e0c,
    name: "doctor-new-select"
  }, {
    path: "/group/member/add",
    component: _3e35d84d,
    name: "group-member-add"
  }, {
    path: "/hospitals/edit/bed",
    component: _30a51ca3,
    name: "hospitals-edit-bed"
  }, {
    path: "/hospitals/edit/medical_staff",
    component: _d6b32a58,
    name: "hospitals-edit-medical_staff"
  }, {
    path: "/hospitals/edit/open_bed",
    component: _598744dd,
    name: "hospitals-edit-open_bed"
  }, {
    path: "/hospitals/edit/outpatient",
    component: _83c79ef0,
    name: "hospitals-edit-outpatient"
  }, {
    path: "/hospitals/edit/patient_date",
    component: _7c290c76,
    name: "hospitals-edit-patient_date"
  }, {
    path: "/hospitals/edit/result",
    component: _58a64c2a,
    name: "hospitals-edit-result"
  }, {
    path: "/lists/hospitals/add",
    component: _e11e79e0,
    name: "lists-hospitals-add"
  }, {
    path: "/notices/new/select",
    component: _db087ce0,
    name: "notices-new-select"
  }, {
    path: "/doctor/new/profile/doctorExpertCategory",
    component: _01fbc7eb,
    name: "doctor-new-profile-doctorExpertCategory"
  }, {
    path: "/doctor/new/profile/pending",
    component: _a81a56ba,
    name: "doctor-new-profile-pending"
  }, {
    path: "/doctor/new/profile/searchPaperMeshList",
    component: _17e7621b,
    name: "doctor-new-profile-searchPaperMeshList"
  }, {
    path: "/doctor/new/profile/surgeryMeshList",
    component: _5fb7a7cc,
    name: "doctor-new-profile-surgeryMeshList"
  }, {
    path: "/doctor/new/profile/unacceptableMeshList",
    component: _98c121ac,
    name: "doctor-new-profile-unacceptableMeshList"
  }, {
    path: "/hospitals/edit/outpatient/edit",
    component: _4613c1c4,
    name: "hospitals-edit-outpatient-edit"
  }, {
    path: "/doctor/new/profile/editHospital/:id?",
    component: _070b259a,
    name: "doctor-new-profile-editHospital-id"
  }, {
    path: "/hospitals/edit/open_bed/:id",
    component: _8e2a4af6,
    name: "hospitals-edit-open_bed-id"
  }, {
    path: "/group/notice/:id",
    component: _86d3b2b2,
    name: "group-notice-id"
  }, {
    path: "/lists/hospitals/:id",
    component: _2e2c87a9,
    name: "lists-hospitals-id"
  }, {
    path: "/doctor/:id",
    component: _6deb25de,
    name: "doctor-id"
  }, {
    path: "/group/:id",
    component: _798e09a8,
    name: "group-id"
  }, {
    path: "/hospitals/:id",
    component: _2ae574e3,
    name: "hospitals-id"
  }, {
    path: "/invitation/:id",
    component: _54dcf0d6,
    name: "invitation-id"
  }, {
    path: "/ms/:id",
    component: _d81c62b6,
    name: "ms-id"
  }, {
    path: "/notices/:id",
    component: _73f76a88,
    name: "notices-id"
  }, {
    path: "/notification/:id",
    component: _5978dca7,
    name: "notification-id"
  }, {
    path: "/papers/:id?",
    component: _b222723a,
    name: "papers-id"
  }, {
    path: "/rooms/:id",
    component: _4cd8d7e5,
    name: "rooms-id"
  }, {
    path: "/doctor/:id/edit",
    component: _0cd8549e,
    name: "doctor-id-edit"
  }, {
    path: "/group/:id/edit",
    component: _5abddac0,
    name: "group-id-edit"
  }, {
    path: "/group/:id/history",
    component: _467a4524,
    name: "group-id-history"
  }, {
    path: "/group/:id/member",
    component: _1d2fa233,
    name: "group-id-member"
  }, {
    path: "/ms/:id?/edit",
    component: _adeadd6c,
    name: "ms-id-edit"
  }, {
    path: "/notices/:id/edit",
    component: _06a9eb47,
    name: "notices-id-edit"
  }, {
    path: "/rooms/:id/edit",
    component: _29a63ac7,
    name: "rooms-id-edit"
  }, {
    path: "/rooms/:id/members",
    component: _046def6f,
    name: "rooms-id-members"
  }, {
    path: "/doctor/:id/edit/category",
    component: _b9611822,
    name: "doctor-id-edit-category"
  }, {
    path: "/doctor/:id/edit/doctorExpertCategory",
    component: _164d2590,
    name: "doctor-id-edit-doctorExpertCategory"
  }, {
    path: "/doctor/:id/edit/email",
    component: _afcef90a,
    name: "doctor-id-edit-email"
  }, {
    path: "/doctor/:id/edit/papers",
    component: _4d1c519b,
    name: "doctor-id-edit-papers"
  }, {
    path: "/doctor/:id/edit/searchPaperMeshList",
    component: _51495f2e,
    name: "doctor-id-edit-searchPaperMeshList"
  }, {
    path: "/doctor/:id/edit/specialty",
    component: _8eec7f46,
    name: "doctor-id-edit-specialty"
  }, {
    path: "/doctor/:id/edit/surgeryMeshList",
    component: _65f1265f,
    name: "doctor-id-edit-surgeryMeshList"
  }, {
    path: "/doctor/:id/edit/unacceptableMeshList",
    component: _b305d712,
    name: "doctor-id-edit-unacceptableMeshList"
  }, {
    path: "/group/:id/notice/new",
    component: _514f4f02,
    name: "group-id-notice-new"
  }, {
    path: "/notices/:id/edit/select",
    component: _41f75c57,
    name: "notices-id-edit-select"
  }, {
    path: "/rooms/:id/members/add",
    component: _49833cfe,
    name: "rooms-id-members-add"
  }, {
    path: "/doctor/:id/edit/papers/delete",
    component: _d499bc9c,
    name: "doctor-id-edit-papers-delete"
  }, {
    path: "/doctor/:id/edit/hospitals/:index",
    component: _0841f99a,
    name: "doctor-id-edit-hospitals"
  }, {
    path: "/rooms/:id/medias/:mediaId?",
    component: _4f6de633,
    name: "rooms-id-medias-mediaId"
  }, {
    path: "/",
    component: _7a887991,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
